import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Westerra',
        projectDesc: 'This is one of the credit platform I built before',
        tags: ['React', 'CSS', 'Material Ui'],
        demo: 'https://www.westerracu.com/',
        image: one
    },
    {
        id: 2,
        projectName: 'Invitae',
        projectDesc: 'In this project, I built and maintained REST APIs using Python Django Framework.',
        tags: ['Django', 'React', 'AWS', 'Jenkins'],
        demo: 'https://www.invitae.com/',
        image: two
    },
    {
        id: 3,
        projectName: 'Joinhoney',
        projectDesc: 'I adopted Django, React, and MUI to build this site from scratch.',
        tags: ['Django', 'React', 'Material Ui', 'GraphQL'],
        demo: 'https://www.joinhoney.com/',
        image: three
    },
    {
        id: 4,
        projectName: 'SFGLife',
        projectDesc: 'I updated some Python packages, optimized Django back end code, restructured the React components for UI, and deployed it on Heroku app.',
        tags: ['React', 'Django', 'Heroku', 'DevOps'],
        demo: 'https://www.sfglife.com/',
        image: four
    },
    /*
    {
        id: 5,
        projectName: 'E-Commerce App',
        projectDesc: 'A Simple E-commerce application',
        tags: ['React Native', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: five
    },
    */
    /*
    {
        id: 6,
        projectName: 'Uber Lite',
        projectDesc: 'Uber clone',
        tags: ['Flutter'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },
    */
    /*
    {
        id: 7,
        projectName: 'Stock Market App',
        projectDesc: 'A simple stock market API app',
        tags: ['React', 'Redux', 'Bootstrap'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: seven
    },
    */
    /*
    {
        id: 8,
        projectName: 'Car Pooling System',
        projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
        tags: ['Flutter', 'React'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },
    */
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
